import React from "react";
import { AiFillGithub } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";

const ProjectLayoutMobile = ({ project }) => {
  return (
    <div className="mobile-card">
      <div className="mobile-links">
        {project.gitlink && (
          <a href={project.gitlink} target="_blank">
            <p className="mobile-card-link-text">GITHUB</p>
          </a>
        )}
        {project.externalLink && (
          <a href={project.externalLink} target="_blank">
            <p className="mobile-card-link-text">SITE</p>
          </a>
        )}
      </div>
      <div className="mobile-card-header">
        <p className="mobile-card-h1">{project.title}</p>
      </div>

      <div className="mobile-card-body">
        <div>
          <img src={project.img[0]} alt="google" />
        </div>
        <div>
          <p class="mobile-card-h2">{project.detail}</p>
        </div>
      </div>

      <div className="mobile-card-tech-list">
        {project.techStack.map((tech) => {
          return <p id="mobile-card-h3">{tech}</p>;
        })}
      </div>
    </div>
  );
};

export default ProjectLayoutMobile;
