import React, {useState} from "react";
import "./experience.css";

const Experience = () => {
  const [isActive, setIsActive] = useState(1);

  // list of company name.
  const companyName = [
    {id: 1, name: "Open Huddle"},
    {id: 2, name: "Optus"},
    {id: 3, name: "Grafias Technology"},
    {id: 4, name: "IT Glance"},
  ];

  // list of company details and tasks done.
  const companyDetails = [
    {
      id: 1,
      title: "Software Engineer",
      date: "2021-",
      address: "Sydney, Australia",
      points: [
        "Creating Optimized API using Django Framework",
        "Testing and refactoring codebase",
        "Tech Stack: Python, Django RestFramework.",
      ],
    },
    {
      id: 2,
      title: "Software Engineer",
      date: "2021-2021",
      address: "Sydney, Australia",
      points: [
        "Optimize and refactor codebase to reduce server response time.",
        "Worked in multiple software projects and participated in the whole software development process.",
        "Developed dynamic and rich single-page applications.",
        "Creating Optimized API using PERL",
        "Tech Stack: Perl, React, JavaScript.",
      ],
    },
    {
      id: 3,
      title: "Software Engineer",
      date: "2018-2019",
      address: "Kathmandu, Nepal",
      points: [
        "Migrating legacy web application using modern technologies.",
        "Developed dynamic and rich single-page applications.",
        "Designed normalized database schema and write well-designed code.",
        "Tech Stack: Django, React, JavaScript.",
      ],
    },
    {
      id: 4,
      title: "Software Engineer Intern",
      date: "2017-2018",
      address: "Kathmandu, Nepal",
      points: [
        "Doing research and Collaborating with team members to find appropriate solutions to the problem.",
        "Writing of well-designed, efficient, testable code.",
        "Built a Restful API that served data to Frontend.",
        "Tech Stack: JavaScript, PHP, Laravel.",
      ],
    },
  ];

  // company name layout.
  const companyLayout = (company, index) => {
    return (
      <p
        id={company.id === isActive ? "company-active" : "company-not-active"}
        key={index}
        onClick={() => {
          setIsActive(company.id);
        }}
      >
        {company.name}
      </p>
    );
  };

  // company detail layout.
  const companyDetailsLayout = (company, index) => {
    return (
      <div
        key={index}
        className={
          company.id === isActive
            ? "company-details-active"
            : "company-details-not-active"
        }
      >
        <p>
          {company.title} ({company.address})
        </p>
        <p id="text-sm">{company.date}</p>

        <div className="company-details-point">
          <p className="short">
            {company.points.map((point, index) => {
              return <p key={index}>👉 {point}</p>;
            })}
          </p>
        </div>
      </div>
    );
  };

  return (
    <section className="component-main-container" id="experience">
      <div className="experience-container">
        <div className="experience-heading-container">
          <p id="section-heading">Where I've Worked</p>
          <div className="experience-horizontal-bar"></div>
        </div>
        <div className="experience-content-container">
          <div className="company-div">
            {companyName.map((company, index) => {
              return companyLayout(company, index);
            })}
          </div>
          <div className="company-details">
            {companyDetails.map((company, index) => {
              return companyDetailsLayout(company, index);
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
