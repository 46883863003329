import React from "react";
import "./projects.css";
import ProjectData from "./ProjectData";
import ProjectLayoutLeft from "./ProjectLayoutLeft";
import ProjectLayoutRight from "./ProjectLayoutRight";
import ProjectLayoutMobile from "./ProjectLayoutMobile";

const Projects = () => {
  return (
    <section className="component-main-container-extra" id="projects">
      <section className="projects-container">
        <div className="project-inner">
          <div className="projects-heading-container">
            <p id="section-heading">Things I've Built</p>
            <div className="projects-horizontal-bar"></div>
          </div>
          <div className="projects-content">
            {ProjectData.map((project, index) => {
              return project.id % 2 === 0 ? (
                <ProjectLayoutLeft project={project} key={index} />
              ) : (
                <ProjectLayoutRight project={project} key={index} />
              );
            })}
          </div>
          <div className="projects-content-mobile">
            {ProjectData.map((project, index) => {
              return <ProjectLayoutMobile project={project} key={index} />;
            })}
          </div>
        </div>
      </section>
    </section>
  );
};

export default Projects;
