import "./App.css";
import Navbar from "./component/Navbar/Navbar";
import LeftSideBar from "./component/LeftSideBar/LeftSideBar";
import Home from "./component/Home/Home";
import RightSideBar from "./component/RightSideBar/RightSideBar";
import About from "./component/About/About";
import Skill from "./component/Skill/Skill";
import Experience from "./component/Experience/Experience";
import Projects from "./component/Projects/Projects";

function App() {
  return (
    <>
      <Navbar />
      <div className="main-container">
        <div className="inner-left-container">
          <LeftSideBar />
        </div>
        <div className="inner-center-container">
          <Home />
          <About />
          <Skill />
          <Experience />
          <Projects />
        </div>
        <div className="inner-right-container">
          <RightSideBar />
        </div>
      </div>
    </>
  );
}

export default App;
