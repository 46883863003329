import React from "react";
import { AiFillGithub } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";

const ProjectLayoutLeft = ({ project, index }) => {
  return (
    <div className="projects-content-container-left" key={index}>
      <img src={project.img} alt="google" />
      <div className="projects-details">
        <div className="project-cards-left">
          <div className="project-links">
            {project.gitlink && (
              <a href={project.gitlink} target="_blank" rel="noreferrer">
                <AiFillGithub size={30} title="Github Link" />
              </a>
            )}
            {project.externalLink && (
              <a href={project.externalLink} target="_blank" rel="noreferrer">
                <AiFillEye size={30} title="External Link" />
              </a>
            )}
          </div>
          <p id="text-large">{project.title}</p>
          <p id="text-small">{project.detail}</p>
          <div className="project-tech-list">
            {project.techStack.map((tech) => {
              return <p id="text-mid">{tech}</p>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectLayoutLeft;
