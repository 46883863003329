const ProjectData = [
  {
    id: 1,
    title: "Crypto Tracker",
    detail:
      "Fullstack Web application for visualizing and getting information regarding your favorite cryptocurrency. Fetched Api from CoinGecko site.",
    techStack: ["Javascript", "React", "OAuth", "TailwindCSS", "Firebase"],
    gitlink:
      "https://github.com/niscee/cryptotracker/tree/master/cryptotracker",
    externalLink: "https://cryptotrackernischal.netlify.app/",
    img: ["./image/crypto.png"],
  },
  {
    id: 2,
    title: "Sorting Visualizer",
    detail:
      "This is a  web app built using React, framer motion library for animation and is used to visualize classic sorting algorithms such as Bubble sort, Selection sort, etc.",
    techStack: ["Javascript", "React", "React Hooks", "Framer-motion"],
    gitlink: "https://github.com/niscee/sorting-visualization",
    externalLink: "https://nischalsortingvisualizer.netlify.app/",
    img: ["./image/sorting.png"],
  },
  {
    id: 3,
    title: "Ecommerce Site",
    detail:
      "A Full functional ecommerce site with user authentication, paypal payment gateway integration and pdf generator.",
    techStack: ["Python", "Django", "Html", "CSS"],
    gitlink: "https://github.com/niscee/Ecommerce-Site",
    externalLink: "",
    img: [
      "./image/ecommerce.png",
      "./image/topitem.png",
      "./image/about.png",
      "./image/cart.png",
      "./image/dashboard.png",
      "./image/dashboard3.png",
      "./image/dashboard2.png",
      "./image/itemdetails.png",
      "./image/paypal.png",
      "./image/paypalfinal.png",
      "./image/pdf.png",
    ],
  },
  {
    id: 4,
    title: "GOGL",
    detail:
      "Google search clone web app. Having almost every features of Google. Fetched Api from RapidApi site.",
    techStack: ["Javascript", "React", "React Hooks", "Tailwind CSS"],
    gitlink: "https://github.com/niscee/google_search_clone",
    externalLink: "https://gogl-search.netlify.app/",
    img: ["./image/gogl.png"],
  },
  {
    id: 5,
    title: "Chat App",
    detail:
      "A web app, can be used for instant messaging with your family, friends, etc.",
    techStack: ["Javascript", "Node", "Express", "Socket.io"],
    gitlink: "https://github.com/niscee/chatapp",
    externalLink: "https://nischalchatapp.herokuapp.com/",
    img: ["./image/chatapp.png"],
  },
  {
    id: 6,
    title: "Git Finder",
    detail: "A web app that pulls users data from Github's Api and sorts users with their detiails.",
    techStack: ["Javascript", "React", "Html", "CSS"],
    gitlink: "https://github.com/niscee/gitBook",
    externalLink: "https://laughing-goldwasser-b9f54a.netlify.app/",
    img: ["./image/gitbook.png"],
  },
];

export default ProjectData;
