import React, { useState, useEffect } from "react";
import "./navbar.css";
import { AiOutlineMenu, AiFillCloseCircle } from "react-icons/ai";

const Navbar = () => {
  const [scroll, setScroll] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);

  const scrollHandler = () => {
    if (window.scrollY >= 20) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const mobileNavLayout = () => {
    return (
      <div class="mobile-navbar">
        <div className="mobile-close">
          <AiFillCloseCircle
            size={40}
            onClick={() => {
              setMobileNav(!mobileNav);
            }}
          />
        </div>
        <div className="mobile-links">
          <ul>
            <a href="#">
              <li>Home</li>
            </a>
            <a href="#about">
              <li>About</li>
            </a>
            <a href="#skills">
              <li>Skills</li>
            </a>
            <a href="#experience">
              <li>Experience</li>
            </a>
            <a href="#projects">
              <li>Projects</li>
            </a>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <section className={scroll ? "navbar-scroll" : "navbar"}>
      <p id="logoName">Nischal</p>
      <div className="right-navbar">
        <ul>
          <a href="#">
            <li>Home</li>
          </a>
          <a href="#about">
            <li>About</li>
          </a>
          <a href="#skills">
            <li>Skills</li>
          </a>
          <a href="#experience">
            <li>Experience</li>
          </a>
          <a href="#projects">
            <li>Projects</li>
          </a>
        </ul>
      </div>
      {!mobileNav && (
        <p
          id="hamburger"
          onClick={() => {
            setMobileNav(!mobileNav);
          }}
        >
          <AiOutlineMenu />
        </p>
      )}

      {mobileNav && mobileNavLayout()}
    </section>
  );
};

export default Navbar;
