import React from "react";
import "./about.css";
import {AiOutlineGithub, AiFillLinkedin} from "react-icons/ai";

const About = () => {
  return (
    <section className="component-main-container" id="about">
      <section className="about-container">
        <div className="about-heading-container">
          <p id="section-heading">About me</p>
          <div className="about-horizontal-bar"></div>
        </div>
        <div className="about-content-container">
          <div>
            <p id="section-secondary-heading">
              I have a master’s degree in Software Engineering from Federation
              University, Melbourne, Australia. I have always been interested in
              coding-related activities, especially in web development.
            </p>
            <p id="section-secondary-heading">
              Previously, I worked at Optus, Sydney, Australia as a Software
              Engineer, where I gained a lot of experience and exposure to
              various technologies and tools.
            </p>
            <p id="section-secondary-heading">
              My interest lies in solving problems related to software systems.
            </p>

            <div className="about-links">
              <a
                href="https://www.linkedin.com/in/nischal-rana-686432143/"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillLinkedin size={30} />
              </a>
              <a
                href="https://github.com/niscee"
                target="_blank"
                rel="noreferrer"
              >
                <AiOutlineGithub className="icons" size={30} />
              </a>
            </div>
          </div>

          <div className="image-div">
            <img alt="user" id="about-image" src="image/nischal.jpeg"></img>
          </div>
        </div>
      </section>
    </section>
  );
};

export default About;
