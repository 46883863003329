import React from "react";
import "./skill.css";

const Skill = () => {
  const skills = [
    {
      title: "Frontend",
      tech: ["Html", "Css", "Javascript", "React"],
      image: "./image/frontend.jpg",
    },
    {
      title: "Backend",
      tech: ["Php", "Python", "Django", "Laravel", "Express"],
      image: "./image/backend.png",
    },
    {
      title: "Database",
      tech: ["MySql", "Sqlite", "MongoDB", "PostgreSQL"],
      image: "./image/database.jpg",
    },
  ];

  const cardStyle = (skill, key) => {
    return (
      <div className="card" key={key}>
        <p id="skills-heading">{skill.title}</p>
        <br></br>
        <img src={skill.image} alt="front" />
        <div className="tools-list">
          {skill.tech.map((tech) => {
            return <p class="language-list">-{tech}&nbsp;</p>;
          })}
        </div>
      </div>
    );
  };

  return (
    <section className="component-main-container" id="skills">
      <section className="skills-container">
        <div className="skills-heading-container">
          <p id="section-heading">Skills</p>
          <div className="skills-horizontal-bar"></div>
        </div>
        <div className="skills-content-container">
          {skills.map((skill, key) => {
            return cardStyle(skill, key);
          })}
        </div>
      </section>
    </section>
  );
};

export default Skill;
