import React from "react";
import { AiFillGithub } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const handleDragStart = (e) => e.preventDefault();

const ProjectLayoutRight = ({project, index}) => {
  const item = project.img.map((trend, key) => {
    return (
      <img
        src={trend}
        onDragStart={handleDragStart}
        key={key}
        role="presentation"
      />
    );
  });

  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1 },
  };

  return (
    <div className="projects-content-container-right" key={index}>
      <div className="projects-details">
        <div className="project-cards-right">
          <div className="project-links">
            {project.gitlink && (
              <a href={project.gitlink} target="_blank">
                <AiFillGithub size={30} title="Github Link" />
              </a>
            )}
            {project.externalLink && (
              <a href={project.externalLink} target="_blank">
                <AiFillEye size={30} title="External Link" />
              </a>
            )}
          </div>
          <p id="text-large">{project.title}</p>
          <p id="text-small">{project.detail}</p>
          <div className="project-tech-list">
            {project.techStack.map((tech) => {
              return <p id="text-mid">{tech}</p>;
            })}
          </div>
        </div>
      </div>
      {project.img.length > 1 ? (
        <AliceCarousel
          items={item}
          mouseTracking
          infinite
          autoPlay
          animationDuration={1500}
          disableDotsControls
          responsive={responsive}
          disableButtonsControls
        />
      ) : (
        <img src={project.img[0]} alt="google" />
      )}
    </div>
  );
};

export default ProjectLayoutRight;
