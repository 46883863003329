import React from "react";
import { AiOutlineGithub, AiFillLinkedin } from "react-icons/ai";
import "./leftsidebar.css";

const LeftSideBar = () => {
  return (
    <section className="left-side">
      <a
        href="https://www.linkedin.com/in/nischal-rana-686432143/"
        target="_blank"
        rel="noreferrer"
      >
        <AiFillLinkedin size={30} />
      </a>
      <a href="https://github.com/niscee" target="_blank" rel="noreferrer">
        <AiOutlineGithub className="icons" size={30} />
      </a>

      <div className="vertical-bar"></div>
    </section>
  );
};

export default LeftSideBar;
