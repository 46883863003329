import React from "react";
import "./rightsidebar.css";


const RightSideBar = () => {
  return (
    <section className="right-side">
      <p id="email">rananischal15@gmail.com</p>
      <div className="vertical-bar"></div>
    </section>
  );
};

export default RightSideBar;
