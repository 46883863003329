import React from "react";
import "./home.css";

const Home = () => {
  return (
    <div className="component-main-container" id="home">
      <div className="innerdiv">
        <p>Hi, my name is</p>
        <p id="name">Nischal Rana.</p>
        <p id="short-bio">I love designing and building things for web.</p>
        <br></br>
        <p id="text-md">
          I'm a software engineer with experience in working with both frontend
          and beckend technologies.
        </p>
        <a href="./docs/cv.pdf" download>
          <button id="btn">Download CV</button>
        </a>
      </div>
    </div>
  );
};

export default Home;
